$(() => {
  function setMobileNavState(flag) {
    let bodyClasses = document.body.classList;
    if (bodyClasses.contains('mobile-menu-open')) {
      bodyClasses.remove('mobile-menu-open');
    } else {
      bodyClasses.add('mobile-menu-open');
    }
  }

  function isMobileNavOpen() {
    return document.body.classList.contains('mobile-menu-open');
  }

  // Close navigation if user clicks outside the nav element when the navigation is open
  $(document).on('click', (e) => {
    let clickedOutsideNav = !$(e.target).closest('.main-navigation').length;
    if (isMobileNavOpen() && clickedOutsideNav) {
      setMobileNavState(false);
    }
  });

  // Toggle the visibility of site navigation when the toggler element is clicked
  $('#mobile-nav-toggler').click((e) => {
    e.stopPropagation();
    e.preventDefault();
    setMobileNavState(!isMobileNavOpen());
  });
});
