$(() => {
  let $grid = $('.masonry-grid').masonry({
    itemSelector: '.masonry-grid-item',
    columnWidth: '.masonry-grid-sizer',
    percentPosition: true,
    horizontalOrder: true,
  });

  // layout Masonry after each image loads
  $grid.imagesLoaded().progress(() => {
    $grid.masonry('layout');
  });
});
