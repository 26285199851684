$(() => {
  /**
   * Get cookie value by cookie name.
   * See https://docs.djangoproject.com/en/1.8/ref/csrf/#ajax
   */
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = $.trim(cookies[i]);
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  // Place up-to-date CSRF token to cached showcase product basket forms.
  function placeShowcaseCsrfTokens() {
    const csrftoken = getCookie('csrftoken');
    $('.homepage-products .basket-form').each(function() {
      $(this).append(`<input type="hidden" name="csrfmiddlewaretoken" value="${csrftoken}">`);
    });
  }

  placeShowcaseCsrfTokens();
});
