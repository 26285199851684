$(() => {
  function setSubnavState($menuItem) {
    let $subMenu = $menuItem.find('ul').first();

    if ($menuItem.hasClass('menu-open')) {
      $menuItem.removeClass('menu-open');
      $subMenu.stop().slideUp('fast'); // hide submenu
    } else {
      $menuItem.addClass('menu-open');
      $subMenu.stop().slideDown('fast'); // show submenu
    }
  }

  // Toggle the visibility of sub menu when the link is clicked
  $('li.toggleable-submenu > a').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();
    $menuItem = $(this).closest('li');
    setSubnavState($menuItem);
  });

  function initializeSidebarMenu() {
    const navOpenDelay = 300;

    // If the submenu has already a menu-open class, display the submenu immediately on page load
    $('li.toggleable-submenu.menu-open').each(function(e) {
      $(this).find('ul').first().show();
    });

    // Add toggle icons for every toggleable menu element
    $('li.toggleable-submenu > a').each(function(e) {
      $(this).prepend('<span class="menu-toggler-icon"></span>');
    });

    // Make sure the main content is taller than the sidebar so that the sidebar won't collapse with footer content
    setTimeout(function() {
      $("#content").css("min-height", $("#sidebar").height() + 50 + "px");
    }, navOpenDelay);
  }

  initializeSidebarMenu();
});
