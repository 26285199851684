$(() => {
  let speed = 7000;
  if (typeof CAROUSEL_SPEED !== 'undefined') {
    speed = CAROUSEL_SPEED * 1000;
  }
  $(".flexslider").flexslider({
    controlNav: true,
    directionNav: true,
    slideshowSpeed: speed,
    nextText: "",
    prevText: "",
    touch: true,
  });
});
